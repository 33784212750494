export function switchFontSizeCookie() {

  /* ロード時のcookie有無チェック */
  window.addEventListener('DOMContentLoaded', (event) => {
  const htmlFont = document.querySelector('html');
      /* cookieを取得 */
      function getCookieArray(){
          var arr = new Array();
          if(document.cookie != ''){
            var tmp = document.cookie.split('; ');
            for(var i=0;i<tmp.length;i++){
                var data = tmp[i].split('=');
                arr[data[0]] = decodeURIComponent(data[1]);
            }
          }
          return arr;
      }
      var arr = getCookieArray();
      var result = arr["value"];

    /* valueのcookieがない時 */
      if(result == null){
          console.log("no cookie");
          document.getElementById("fontDefault").classList.add('is_active');
      } 
      // cookieがある場合
      else {
          var theme = result;

          // valueの値でfontDefaultCookieがある時
          if(theme == 'fontDefaultCookie'){
            document.getElementById("fontDefault").classList.add('is_active');
            document.getElementById("fontBig").classList.remove('is_active');
            console.log('fontDefaultOn');
            if (window.matchMedia('(max-width: 767px)').matches) {
              htmlFont.style.fontSize = 'calc(100vw / 23.4436)';
            }else{
              htmlFont.style.fontSize = '100%';
            }
          }
          // valueの値でfontBigCookieがある時
          else if(theme == 'fontBigCookie'){
            document.getElementById("fontBig").classList.add('is_active');
            document.getElementById("fontDefault").classList.remove('is_active');
            console.log('fontBigOn');
            if (window.matchMedia('(max-width: 767px)').matches) {
              htmlFont.style.fontSize = 'calc(110vw / 23.4436)';
            }else{
              htmlFont.style.fontSize = '110%';
            }
          }
          else {
            console.log('none');
          }
      }
    });
    
    if (window.matchMedia('(max-width: 767px)').matches) {

      /* フォントサイズを標準に */
      document.querySelector('#fontDefault').addEventListener('click', () => {
      document.getElementById("fontDefault").classList.add('is_active');
      document.getElementById("fontBig").classList.remove('is_active');
      const htmlFont = document.querySelector('html');
      htmlFont.style.fontSize = 'calc(100vw / 23.4436)';
      // cookieの操作
      document.cookie = "value=fontBigCookie;max-age=0;";
      document.cookie = "value=fontDefaultCookie;max-age=9999;";
      console.log('fontDefaultOn');
      });
      
      /* フォントサイズを大きく */
      document.querySelector('#fontBig').addEventListener('click', () => {
      document.getElementById("fontBig").classList.add('is_active');
      document.getElementById("fontDefault").classList.remove('is_active');
      const htmlFont = document.querySelector('html');
      htmlFont.style.fontSize = 'calc(110vw / 23.4436)';
      // cookieの操作
      document.cookie = "value=fontDefaultCookie;max-age=0;";
      document.cookie = "value=fontBigCookie;max-age=9999;";
      console.log('fontBigOn');
      });

    }else{


      /* フォントサイズを標準に */
      document.querySelector('#fontDefault').addEventListener('click', () => {
      document.getElementById("fontDefault").classList.add('is_active');
      document.getElementById("fontBig").classList.remove('is_active');
      const htmlFont = document.querySelector('html');
      htmlFont.style.fontSize = '100%';
      // cookieの操作
      document.cookie = "value=fontBigCookie;max-age=0;";
      document.cookie = "value=fontDefaultCookie;max-age=9999;";
      console.log('fontDefaultOn');
      });
      
      /* フォントサイズを大きく */
      document.querySelector('#fontBig').addEventListener('click', () => {
      document.getElementById("fontBig").classList.add('is_active');
      document.getElementById("fontDefault").classList.remove('is_active');
      const htmlFont = document.querySelector('html');
      htmlFont.style.fontSize = '110%';
      // cookieの操作
      document.cookie = "value=fontDefaultCookie;max-age=0;";
      document.cookie = "value=fontBigCookie;max-age=9999;";
      console.log('fontBigOn');
      });

    }
}
